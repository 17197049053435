<template>
    <div class="login-container">
        <img src="" alt="" srcset="" />
        <div class="login-frame">
            <div class="login-banner-info">
                <div class="login-info-text">
                    <div class="text-title" v-html="loginOrgInfo.LineText1">
                    </div>
                    <div class="text-info" v-html="loginOrgInfo.LineText2">
                    </div>
                    <div class="text-info" v-html="loginOrgInfo.LineText3">
                    </div>
                </div>
            </div>
            <div v-if="!authkeyLogin" class="login-form">
                <div class="login-form-tabqrcode" v-if="false && logintype != 'qrcode'">
                    <img class="tips" src="@/assets/login/login-qrcode-tips.png" />
                    <img class="qrcode" src="@/assets/login/login-qrcode-tab.png" alt="扫码登录"
                        @click="onFormType('qrcode')" />
                </div>
                <div class="login-input-type" v-if="logintype != 'qrcode'">
                    <div class="logintype-item" :class="{ active: logintype == 'passcode' }">
                        <span class="text" @click="onFormType('passcode')">帐号登录</span>
                    </div>
                    <div class="logintype-item" v-if="false" :class="{ active: logintype == 'vercode' }">
                        <span class="text" @click="onFormType('vercode')">验证码登录</span>
                    </div>
                </div>
                <el-form ref="loginForm" :model="loginFormData">
                    <div class="login-input-form" v-if="logintype == 'passcode'">
                        <!-- <el-form-item prop="orgcode" key="orgcode">
                            <el-input
                                placeholder="机构号"
                                v-model="orgcode"
                                @blur="onLoadInfo"
                            >
                                <img
                                    slot="prefix"
                                    class="input-prefix"
                                    src="@/assets/login/icon-org.png"
                                />
                            </el-input>
                        </el-form-item> -->

                        <el-form-item prop="account" key="account">
                            <el-input placeholder="请输入帐号" v-model="loginFormData.account">
                                <img slot="prefix" class="input-prefix" src="@/assets/login/icon-user.png" />
                            </el-input>
                        </el-form-item>
                        <el-form-item prop="password" key="password">
                            <el-input placeholder="请输入密码" v-model="loginFormData.password" type="password">
                                <img slot="prefix" class="input-prefix" src="@/assets/login/icon-pass.png" />
                            </el-input>
                        </el-form-item>
                        <el-form-item prop="captcha" key="captcha">
                            <el-input placeholder="请输入验证码" v-model="loginFormData.captcha"
                                @keyup.enter.native="submitForm()">
                                <img slot="prefix" class="input-prefix" src="@/assets/login/icon-pass.png" />

                                <el-image slot="suffix" style="margin-top: 5px" :src="captchaImgUrl"
                                    @click="onUpdateCaptcha">
                                    <div slot="placeholder" class="image-slot">
                                        加载中<span class="dot">...</span>
                                    </div>
                                </el-image>
                            </el-input>
                        </el-form-item>
                        <el-row justify="space-between">
                            <el-col :span="12">
                                <div class="login-error-tip">
                                    {{ errortext }}
                                </div>
                            </el-col>
                            <el-col :span="12">
                                <div class="forgot-pass">
                                    <el-link slot="suffix" type="primary" style="margin-right: 4px"
                                        :underline="false">忘记密码</el-link>
                                </div>
                            </el-col>
                        </el-row>
                        <el-form-item>
                            <el-button type="primary" class="login-btn-submit" @click="submitForm()"
                                :loading="loading">登录</el-button>
                        </el-form-item>
                    </div>
                    <div class="login-input-form" v-if="logintype == 'vercode'">
                        <el-form-item prop="mobile" key="mobile">
                            <el-input placeholder="请输入手机号码" v-model="loginFormData.mobile">
                                <img slot="prefix" class="input-prefix" src="@/assets/login/icon-user.png" />
                            </el-input>
                        </el-form-item>
                        <el-form-item prop="vercode" key="vercode">
                            <el-input placeholder="请输入验证码" v-model="loginFormData.vercode">
                                <img slot="prefix" class="input-prefix" src="@/assets/login/icon-pass.png" />
                                <el-link slot="suffix" type="primary" style="margin-right: 4px"
                                    :underline="false">获取验证码</el-link>
                            </el-input>
                        </el-form-item>
                        <div class="forgot-pass"></div>
                        <el-form-item>
                            <el-button type="primary" class="login-btn-submit" @click="submitForm()">登录</el-button>
                        </el-form-item>
                    </div>
                </el-form>

                <div class="login-input-type login-qrcode-inputtype" v-if="logintype == 'qrcode'">
                    <div class="logintype-item active">
                        <span class="text">扫码登录</span>
                    </div>
                </div>
                <div class="login-qrcode-form" v-if="logintype == 'qrcode'">
                    <div class="login-qrcode">
                        <img class="login-qrcode-image" src="@/assets/login/login-qrcode.png" alt="" srcset="" />
                    </div>
                    <div class="login-qrcode-tips">
                        请使用微信等工具扫码登录后台
                    </div>
                    <div class="logintype-qrcode">
                        <el-link slot="suffix" type="primary" style="margin-right: 4px" :underline="false"
                            @click="onFormType('passcode')">帐号密码登录</el-link>
                        <el-link slot="suffix" type="primary" style="margin-right: 4px" :underline="false"
                            @click="onFormType('vercode')">验证码登录</el-link>
                    </div>
                </div>
            </div>
            <div class="login-form login-form-authkey" v-else style="height: 100%;display: flex;align-items: center;"
                v-loading="loading">
            </div>
        </div>
        <!-- <div class="login-footer-rights">
      © 2020 中国地质环境监测院 All Rights Reserved
    </div> -->
    </div>
</template>
<style lang="scss" scoped>
.login-container {
    background: #003271 url("~@/assets/login/login-bg.png");
    background-size: cover;
    min-height: 500px;
}

.login-form-authkey {
    ::v-deep {
        .el-loading-mask {
            background: none;
        }
    }
}

@media (max-width: 650px) {
    .login-container {
        min-height: auto;
    }
}
</style>
<script>
export default {
    data() {
        return {
            authkeyLogin: false,//是否使用authkey登录
            logintype: "passcode",
            orgcode: "",
            loginOrgInfo: "",
            loginFormData: {
                account: "",
                password: "",
                captcha: "",

                mobile: "",
                vercode: "",

                qrcodeurl: "",
            },
            loading: false,
            verimgurlParame: "",
            errortext: "",
        };
    },
    computed: {
        captchaImgUrl: function () {
            return (
                this.$extconfig.getConfig("apibase", "/") +
                "/api/Login/GetAuthCode?t=" +
                this.verimgurlParame
            );
        },
    },
    methods: {
        onFormType(logintype) {
            this.logintype = logintype;
        },
        onUpdateCaptcha() {
            this.verimgurlParame = new Date().getTime();
        },
        submitForm() {
            this.$refs["loginForm"].validate((valid) => {
                if (valid) {
                    if (!this.loginFormData.account) {
                        this.$message.error("请填写登录帐号");
                        return;
                    }
                    if (!this.loginFormData.password) {
                        this.$message.error("请填写登录密码");
                        return;
                    }
                    if (!this.loginFormData.captcha) {
                        this.$message.error("请填写验证码");
                        return;
                    }
                    this.loading = true;
                    this.$store
                        .dispatch("user/login", {
                            name: this.loginFormData.account,
                            password: this.loginFormData.password,
                            captcha: this.loginFormData.captcha,
                        })
                        .then(() => {
                            this.loading = false;
                            if (
                                this.$route.query.redirect &&
                                this.$route.query.redirect != ""
                            ) {
                                this.$router.push(this.$route.query.redirect);
                            } else {
                                let usermenu = this.$store.getters.sidebarmenu;
                                if (usermenu.length > 0) {
                                    this.$router.push(usermenu[0].url);
                                } else {
                                    this.errortext = "无访问权限";
                                }
                            }
                        })
                        .catch((err) => {
                            this.onUpdateCaptcha();
                            this.loading = false;
                            this.errortext = err;
                        });
                } else {
                    return false;
                }
            });
        },
        onLoadInfo() {
            this.$store
                .dispatch("user/getLoginPageInfo", this.orgcode)
                .then((res) => {
                    this.loginOrgInfo = res;
                    this.orgcode = res.LoginCode;
                });
        },
    },
    created() {
        let authkey = this.$route.query.authkey;
        if (authkey) {
            this.authkeyLogin = true;
            //单点登录，用值填入账号密码进行登录即可
            this.loading = true;
            this.$store
                .dispatch("user/login", {
                    name: authkey,
                    password: authkey,
                    captcha: '',
                })
                .then((res) => {
                    if (
                        this.$route.query.redirect &&
                        this.$route.query.redirect != ""
                    ) {
                        this.$router.push(this.$route.query.redirect);
                    } else {
                        let usermenu = this.$store.getters.sidebarmenu;
                        if (usermenu.length > 0) {
                            this.$router.push(usermenu[0].url);
                        } else {
                            this.errortext = "无访问权限";
                        }
                    }
                })
                .catch((err) => {
                    this.authkeyLogin = false;
                    this.errortext = '';
                });
        }

        this.verimgurlParame = new Date().getTime();
        if (this.$route.query.orgcode) {
            this.orgcode = this.$route.query.orgcode;
            this.onLoadInfo();
        } else {
            var loginPageInfo = this.$store.state.user.loginPageInfo;
            if (loginPageInfo) {
                this.orgcode = loginPageInfo.LoginCode;
                this.loginOrgInfo = loginPageInfo;
            } else {
                this.orgcode = "default";
                this.onLoadInfo();
            }
        }

        if (process.env.NODE_ENV == "development") {
            this.loginFormData.account = "xxgsjk";
            this.loginFormData.password = "123456";
        }
    },
};
</script>